import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
// import 'react-perfect-scrollbar/dist/css/styles.css';

// import App from "./App";

const App = lazy(() => import("./App"));

ReactDOM.render(
  <Suspense fallback={<div className="loading" />}>
    <App />
  </Suspense>,
  document.getElementById("root")
);
